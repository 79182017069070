import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

const ProfileModal = ({ clientID, show, onHide }) => {
    const [profile, setProfile] = useState({ name: '', email: '', clientID: '' });

    useEffect(() => {
        // Fetch profile details from the main database
        const fetchProfile = async () => {
            try {
                const response = await axios.get('https://track.howtoretain.in/api/client-profile', {
                    headers: {
                        'Client-ID': clientID,
                    },
                });

                setProfile(response.data);
            } catch (error) {
                console.error('Error fetching client profile:', error);
            }
        };

        if (clientID) {
            fetchProfile();
        }
    }, [clientID]);

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><strong>Name:</strong> {profile.name || 'N/A'}</p>
                <p><strong>Email:</strong> {profile.email || 'N/A'}</p>
                <p><strong>Client ID:</strong> {profile.clientID}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ProfileModal;