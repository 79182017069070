import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Table.css'; // Common table styles

const TargetingGroups = ({ clientID }) => {
  const [targetingGroups, setTargetingGroups] = useState([]);  // Initialize as an empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch targeting groups from the backend
  useEffect(() => {
    const fetchTargetingGroups = async () => {
      console.log("Targeting Groups API called");
      try {
        const response = await axios.get('https://track.howtoretain.in/api/targeting-groups',
          {
            headers: {
              'Client-ID': clientID, // Include clientID in the headers
            },
          }
        );  // Adjust the API endpoint if needed
        console.log(response);

        // Check if the response is an array before setting it in state
        if (Array.isArray(response.data)) {
          setTargetingGroups(response.data);  // Set targeting groups to the array returned by the API
        } else {
          throw new Error("Invalid data format");
        }

        setLoading(false);
      } catch (err) {
        console.error("Error fetching targeting groups:", err);
        setError('Failed to fetch targeting groups');
        setLoading(false);
      }
    };

    fetchTargetingGroups();
  }, []);  // Empty dependency array means this will run once when the component is mounted

  return (
    <div>
      {/* <div className="actions">
        <button className="create-btn">Create</button> 
        <button className="delete-btn">Delete</button>
      </div> */}

      {error && <div>{error}</div>}  {/* Display error if any */}

      {/* Targeting Group Table */}
      {!error && (
        <table className="table">
          <thead>
            <tr>
              {/* <th><input type="checkbox" /></th> */}
              <th>Targeting Group Name</th>  {/* Replaced Campaign Name with Targeting Group Name */}
              {/* <th>Status</th> */}
              {/* <th>On/Off</th> */}
              <th>Description</th>
              {/* <th>Goal</th>
              <th>Impressions</th>
              {/* <th>Reach</th>
              <th>Clicked</th>
              <th>CTR (%)</th>
              <th>Unique CTR (%)</th>
              <th>Conversions</th>
              <th>Conv. Rate (%)</th>  */}
            </tr>
          </thead>
          <tbody>
            {targetingGroups.map((group) => (
              <tr key={group._id}>
                {/* <td><input type="checkbox" /></td> */}
                <td>{group.name}</td>  {/* Targeting Group Name */}
                {/* <td>{group.status}</td> */}
                {/* <td>
                  <label className="switch">
                    <input type="checkbox" checked={group.isActive} readOnly />
                    <span className="slider round"></span>
                  </label>
                </td> */}
                <td>{group.description}</td>
                {/* <td>{group.goal}</td> 
                <td>{group.performance?.impressions}</td>
                {/* <td>{group.performance?.reach}</td>
                <td>{group.performance?.clicks}</td>
                <td>{group.performance ? ((group.performance.clicks / group.performance.impressions) * 100).toFixed(2) : 'N/A'}</td>
                {/* <td>{group.performance ? ((group.performance.clicks / group.performance.reach) * 100).toFixed(2) : 'N/A'}</td>
                <td>{group.performance?.conversions}</td>
                <td>{group.performance ? ((group.performance.conversions / group.performance.impressions) * 100).toFixed(2) : 'N/A'}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TargetingGroups;