import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CampaignCreationModal.css'; // Custom styles

const CampaignCreationModal = ({ clientID, onClose }) => {
  const [step, setStep] = useState(1);  // Step navigation (1: Campaign, 2: Targeting Group, 3: Creatives)
  // UTM data states
  const [utmData, setUtmData] = useState([]);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [mediumOptions, setMediumOptions] = useState([]);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [contentOptions, setContentOptions] = useState([]);
  const [termOptions, setTermOptions] = useState([]);

  // Event types (goals)
  const [eventTypes, setEventTypes] = useState([]);
  
  // Selected UTM Parameters
  const [selectedSources, setSelectedSources] = useState([]);
  const [selectedMediums, setSelectedMediums] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [selectedContents, setSelectedContents] = useState([]);
  const [selectedTerms, setSelectedTerms] = useState([]);
  const [selectedUtmParameters, setSelectedUtmParameters] = useState({
    utmSource:Array,
    utmMedium:Array,
    utmCampaign:Array,
    utmContent:Array,
    utmTerm:Array
  });  // To store all selected UTM parameters

  // Campaign and Targeting Group states
  const [campaignData, setCampaignData] = useState({
    name: '',
    description: '',
    goal: '',
    attributionWindowViewed: '1',
    attributionWindowClicked: '1',
    _id:''
  });
  const [targetingGroupData, setTargetingGroupData] = useState({
    name: '',
    description: '',
    sessionTargeting: 'First Session',  // Default session type
    utmParameters: [],
    _id:''
  });

  // States for Creative data
  const [creativeData, setCreativeData] = useState({
    name: '',
    description: '',
    device: '',
    images: {
      desktop: String,
      mobile: String,
    },
    redirectUrl: '',
    utmParameters: {
      utmSource: '',
      utmMedium: '',
      utmCampaign: '',
      utmContent: '',
      utmTerm: '',
    }
  });
  const [deviceSpecificUrls, setDeviceSpecificUrls] = useState({
    desktop: '',
    mobile: ''
  });

  // Fetch UTM data when component mounts
  useEffect(() => {
    const fetchUTMData = async () => {
      if (!clientID) return; // Don't fetch if clientID is not available
      try {
        const response = await axios.get('https://track.howtoretain.in/api/track', {
          headers: {
            'Client-ID': clientID, // Include clientID in headers
          },
        });
        setUtmData(response.data);

        const uniqueSources = [...new Set(response.data.map(item => item.acquisitionSource.source))];
        setSourceOptions(uniqueSources);  // Set unique sources in dropdown
      } catch (err) {
        console.error("Error fetching UTM data:", err);
      }
    };

    fetchUTMData();
  }, [clientID]);

  // Fetch event goals when component mounts
  useEffect(() => {
    const fetchGoals = async () => {
        if (!clientID) return; // Don't fetch if clientID is not available
        console.log('Fetching event-list with clientID:', clientID); // Debugging
        try {
            const response = await axios.get('https://track.howtoretain.in/api/event-list', {
                headers: {
                    'Client-ID': clientID, // Include clientID in headers
                },
            });
            console.log('API response for event-list:', response.data); // Debugging statement
            
            // Ensure the data has the correct structure
            if (Array.isArray(response.data) && response.data.every(item => item.eventType)) {
                const eventTypes = response.data.map(item => item.eventType);  // Extract event types
                setEventTypes(eventTypes);
            } else {
                console.error('Invalid response format for event-list:', response.data);
            }
        } catch (err) {
            console.error("Error fetching event types:", err);
        }
    };

    fetchGoals();
}, [clientID]);

  // Handle UTM parameter cascading
const handleSourceSelectChange = (e) => {
    const selectedSources = Array.from(e.target.selectedOptions, option => option.value);
    setSelectedSources(selectedSources);

    const filteredMediums = utmData
      .filter(item => selectedSources.includes(item.acquisitionSource.source))
      .map(item => item.acquisitionSource.medium);
    setMediumOptions([...new Set(filteredMediums)]);
  };

  const handleMediumSelectChange = (e) => {
    const selectedMediums = Array.from(e.target.selectedOptions, option => option.value);
    setSelectedMediums(selectedMediums);

    const filteredCampaigns = utmData
      .filter(item => selectedSources.includes(item.acquisitionSource.source) &&
                      selectedMediums.includes(item.acquisitionSource.medium))
      .map(item => item.acquisitionSource.campaign);
    setCampaignOptions([...new Set(filteredCampaigns)]);
  };

  const handleCampaignSelectChange = (e) => {
    const selectedCampaigns = Array.from(e.target.selectedOptions, option => option.value);
    setSelectedCampaigns(selectedCampaigns);

    const filteredContents = utmData
      .filter(item => selectedSources.includes(item.acquisitionSource.source) &&
                      selectedMediums.includes(item.acquisitionSource.medium) &&
                      selectedCampaigns.includes(item.acquisitionSource.campaign))
      .map(item => item.acquisitionSource.content);
    setContentOptions([...new Set(filteredContents)]);
    
  };

  const handleContentSelectChange = (e) => {
    const selectedContents = Array.from(e.target.selectedOptions, option => option.value);
    setSelectedContents(selectedContents);

    const filteredTerms = utmData
      .filter(item => selectedSources.includes(item.acquisitionSource.source) &&
                      selectedMediums.includes(item.acquisitionSource.medium) &&
                      selectedCampaigns.includes(item.acquisitionSource.campaign) &&
                      selectedContents.includes(item.acquisitionSource.content))
      .map(item => item.acquisitionSource.term);
    setTermOptions([...new Set(filteredTerms)]);
    
  };

  const handleTermSelectChange = (e) => {
    const selectedTerms = Array.from(e.target.selectedOptions, option => option.value);
    setSelectedTerms(selectedTerms);
  };

  
  // Campaign and Targeting Group Handlers
  const handleCampaignChange = (e) => {
    const { name, value } = e.target;
    setCampaignData({ ...campaignData, [name]: value });
  };

  const handleTargetingGroupChange = (e) => {
    const { name, value } = e.target;
    setTargetingGroupData({ ...targetingGroupData, [name]: value });
    console.log("targetinggroup: "+ targetingGroupData);
  };

  // Save Campaign and proceed
  const handleSaveCampaign = async () => {
    try {
      const response = await axios.post('https://track.howtoretain.in/api/campaigns', {
        ...campaignData,
        status: 'Draft',  // Save as draft
      }, {
        headers: {
          'Client-ID': clientID, // Include clientID in headers
        },
      });
      console.log('Campaign saved:', response.data);  // Log campaign response to check _id
      setCampaignData({ ...campaignData, _id: response.data._id });  // Ensure the campaign ID is saved
      setStep(2);  // Move to next step (Targeting Groups)
    } catch (err) {
      console.error("Error saving campaign:", err);
    }
  };  

  const handleSaveTargetingGroup = async () => {
    try {
      if (!campaignData._id) {
        console.error("Campaign ID is missing");
        return;
      }
      const response = await axios.post('https://track.howtoretain.in/api/targeting-groups', {
        ...targetingGroupData,
        utmParameters: {
          utmSource: selectedSources,
          utmMedium: selectedMediums,
          utmCampaign: selectedCampaigns,
          utmContent: selectedContents,
          utmTerm: selectedTerms,
        },
        campaign: campaignData._id,
      }, {
        headers: {
          'Client-ID': clientID, // Include clientID in headers
        },
      });
      console.log('Targeting group saved:', response.data);
      setTargetingGroupData({ ...targetingGroupData, _id: response.data._id });  // Ensure the targeting group ID is saved
      setStep(3);  // Move to next step (Creatives)
    } catch (err) {
      console.error("Error saving targeting group:", err.response ? err.response.data : err.message);
    }
  };

  // Handle Creative Data Change
  const handleCreativeChange = (e) => {
    const { name, value } = e.target;
  
    // Check if the name corresponds to a UTM parameter
    if (name.startsWith('utm')) {
      // Update UTM parameters separately
      setCreativeData((prevData) => ({
        ...prevData,
        utmParameters: {
          ...prevData.utmParameters,
          [name]: value, // Update the specific UTM field
        }
      }));
    } else {
      // Otherwise, update other creative fields
      setCreativeData({ ...creativeData, [name]: value });
    }
  };

  const handleDeviceChange = (e) => {
    setCreativeData({ ...creativeData, device: e.target.value });
    if (e.target.value === 'Both') {
      setDeviceSpecificUrls({ desktop: '', mobile: '' });
    } else if (e.target.value === 'Desktop') {
      setDeviceSpecificUrls({ desktop: '', mobile: null });
    } else if (e.target.value === 'Mobile') {
      setDeviceSpecificUrls({ desktop: null, mobile: '' });
    }
  };

  const handleImageUrlChange = (e) => {
    const { name, value } = e.target;
    setDeviceSpecificUrls({ ...deviceSpecificUrls, [name]: value });
  };

  const handleSaveCreative = async () => {
    try {
      const { name, description, device, redirectUrl, utmParameters } = creativeData;
      const { desktop, mobile } = deviceSpecificUrls;

      if (!name || !description || !redirectUrl || (device === 'Both' && (!desktop || !mobile))) {
        alert('Please fill all required fields');
        return;
      }

      const newCreative = {
        ...creativeData,
        images: {
          desktop: desktop || null,
          mobile: mobile || null
        }
      };

      // Send creative data to the backend
      const response = await axios.post('https://track.howtoretain.in/api/creatives', {
        ...newCreative,
        campaign: campaignData._id,
        targetingGroup: targetingGroupData._id,
      }, {
        headers: {
          'Client-ID': clientID, // Include clientID in headers
        },
      });
      console.log('Creative saved:', response.data);
      setStep(1);  // Reset back to Campaign step
      onClose();   // Close modal
    } catch (error) {
      console.error("Error saving creative:", error);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {/* Sidebar for navigation */}
        <div className="left-sidebar">
          <ul>
            <li className={step === 1 ? 'active' : ''} onClick={() => setStep(1)}>
              📝 New Campaign
            </li>
            <li className={step === 2 ? 'active' : ''} onClick={() => setStep(2)}>
              🎯 New Targeting Groups
            </li>
            <li className={step === 3 ? 'active' : ''} onClick={() => setStep(3)}>
              🎨 New Creatives
            </li>
          </ul>
        </div>
  
        {/* Main content section */}
        <div className="right-content">
          {step === 1 && (
            <div className="form-section">
              <h2>New Campaign</h2>
              <div className="form-group">
                <label>Campaign Name</label>
                <input
                  type="text"
                  name="name"
                  value={campaignData.name}
                  onChange={handleCampaignChange}
                  placeholder="Enter campaign name"
                  required
                />
              </div>
  
              <div className="form-group">
                <label>Description</label>
                <textarea
                  name="description"
                  value={campaignData.description}
                  onChange={handleCampaignChange}
                  placeholder="Enter description"
                  required
                />
              </div>
  
              <div className="form-group">
                <label>Goal</label>
                <select
                  name="goal"
                  value={campaignData.goal}
                  onChange={handleCampaignChange}
                  required
                >
                  <option value="">Select a goal</option>
                  {eventTypes.map((goal, index) => (
                    <option key={index} value={goal}>
                      {goal}
                    </option>
                  ))}
                </select>
              </div>
  
              <div className="form-group">
                <label>Attribution Windows</label>
                <div className="form-subgroup">
                  <label>Viewed Through Conversion</label>
                  <select
                    name="attributionWindowViewed"
                    value={campaignData.attributionWindowViewed}
                    onChange={handleCampaignChange}
                  >
                    {[...Array(14)].map((_, i) => (
                      <option key={i} value={i + 1}>{i + 1} Day(s)</option>
                    ))}
                  </select>
                </div>
                <div className="form-subgroup">
                  <label>Clicked Through Conversion</label>
                  <select
                    name="attributionWindowClicked"
                    value={campaignData.attributionWindowClicked}
                    onChange={handleCampaignChange}
                  >
                    {[...Array(14)].map((_, i) => (
                      <option key={i} value={i + 1}>{i + 1} Day(s)</option>
                    ))}
                  </select>
                </div>
              </div>
  
              <button className="primary-button" onClick={handleSaveCampaign}>Save & Next</button>
            </div>
          )}
  
          {step === 2 && (
            <div className="form-section">
              <h2>New Targeting Groups</h2>
              <div className="form-group">
                <label>Targeting Group Name</label>
                <input
                  type="text"
                  name="name"
                  value={targetingGroupData.name}
                  onChange={handleTargetingGroupChange}
                  placeholder="Enter targeting group name"
                  required
                />
              </div>
  
              <div className="form-group">
                <label>Description</label>
                <textarea
                  name="description"
                  value={targetingGroupData.description}
                  onChange={handleTargetingGroupChange}
                  placeholder="Enter description"
                  required
                />
              </div>
  
              <div className="form-group">
                <label>Source</label>
                <select
                  name="source"
                  multiple
                  value={selectedSources}
                  onChange={handleSourceSelectChange}
                >
                  {sourceOptions.map((source, index) => (
                    <option key={index} value={source}>{source}</option>
                  ))}
                </select>
              </div>
  
              <div className="form-group">
                <label>Medium</label>
                <select
                  name="medium"
                  multiple
                  value={selectedMediums}
                  onChange={handleMediumSelectChange}
                  disabled={selectedSources.length === 0}
                >
                  {mediumOptions.map((medium, index) => (
                    <option key={index} value={medium}>{medium}</option>
                  ))}
                </select>
              </div>
  
              <div className="form-group">
                <label>Campaign</label>
                <select
                  name="campaign"
                  multiple
                  value={selectedCampaigns}
                  onChange={handleCampaignSelectChange}
                  disabled={selectedMediums.length === 0}
                >
                  {campaignOptions.map((campaign, index) => (
                    <option key={index} value={campaign}>{campaign}</option>
                  ))}
                </select>
              </div>
  
              <div className="form-group">
                <label>Content</label>
                <select
                  name="content"
                  multiple
                  value={selectedContents}
                  onChange={handleContentSelectChange}
                >
                  {contentOptions.map((content, index) => (
                    <option key={index} value={content}>{content}</option>
                  ))}
                </select>
              </div>
  
              <div className="form-group">
                <label>Term</label>
                <select
                  name="term"
                  multiple
                  value={selectedTerms}
                  onChange={handleTermSelectChange}
                >
                  {termOptions.map((term, index) => (
                    <option key={index} value={term}>{term}</option>
                  ))}
                </select>
              </div>
  
              <div className="form-group">
                <label>Session Type</label>
                <select
                  name="sessionTargeting"
                  onChange={handleTargetingGroupChange}
                  value={targetingGroupData.sessionTargeting}
                >
                  <option value="First Session">First Session</option>
                  <option value="Last Session">Last Session</option>
                </select>
              </div>
  
              <button className="primary-button" onClick={handleSaveTargetingGroup}>Save & Next</button>
            </div>
          )}
  
          {step === 3 && (
            <div className="form-section">
              <h2>New Creative</h2>
  
              <div className="form-group">
                <label>Creative Name</label>
                <input
                  type="text"
                  name="name"
                  value={creativeData.name}
                  onChange={handleCreativeChange}
                  placeholder="Enter creative name"
                  required
                />
              </div>
  
              <div className="form-group">
                <label>Description</label>
                <textarea
                  name="description"
                  value={creativeData.description}
                  onChange={handleCreativeChange}
                  placeholder="Enter description"
                  required
                />
              </div>
  
              <div className="form-group">
                <label>Device</label>
                <select
                  name="device"
                  value={creativeData.device}
                  onChange={handleDeviceChange}
                  required
                >
                  <option value="">Select a device</option>
                  <option value="Desktop">Desktop</option>
                  <option value="Mobile">Mobile</option>
                  <option value="Both">Both</option>
                </select>
              </div>
  
              {creativeData.device === 'Desktop' && (
                <div className="form-group">
                  <label>Desktop Image URL</label>
                  <input
                    type="url"
                    name="desktop"
                    value={deviceSpecificUrls.desktop}
                    onChange={handleImageUrlChange}
                    placeholder="Enter desktop image URL"
                    required
                  />
                </div>
              )}
  
              {creativeData.device === 'Mobile' && (
                <div className="form-group">
                  <label>Mobile Image URL</label>
                  <input
                    type="url"
                    name="mobile"
                    value={deviceSpecificUrls.mobile}
                    onChange={handleImageUrlChange}
                    placeholder="Enter mobile image URL"
                    required
                  />
                </div>
              )}
  
              {creativeData.device === 'Both' && (
                <>
                  <div className="form-group">
                    <label>Desktop Image URL</label>
                    <input
                      type="url"
                      name="desktop"
                      value={deviceSpecificUrls.desktop}
                      onChange={handleImageUrlChange}
                      placeholder="Enter desktop image URL"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Mobile Image URL</label>
                    <input
                      type="url"
                      name="mobile"
                      value={deviceSpecificUrls.mobile}
                      onChange={handleImageUrlChange}
                      placeholder="Enter mobile image URL"
                      required
                    />
                  </div>
                </>
              )}
  
              <div className="form-group">
                <label>Redirect URL</label>
                <input
                  type="url"
                  name="redirectUrl"
                  value={creativeData.redirectUrl}
                  onChange={handleCreativeChange}
                  placeholder="Enter redirect URL"
                  required
                />
              </div>
  
              <button className="primary-button" onClick={handleSaveCreative}>Save Creative</button>
            </div>
          )}
        </div>
  
        <button className="close-btn" onClick={onClose}>X</button>
      </div>
    </div>
  );  
};

export default CampaignCreationModal;