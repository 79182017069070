import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Table.css'; // Common table styles

const Creatives = ({ clientID }) => {
  const [creatives, setCreatives] = useState([]);  // Initialize as an empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch creatives from the backend
  useEffect(() => {
    const fetchCreatives = async () => {
      console.log("Creatives API called");
      try {
        const response = await axios.get('https://track.howtoretain.in/api/creatives',{
          headers: {
            'Client-ID': clientID, // Include clientID in the headers
          },
        });  // Adjust the API endpoint if needed
        console.log(response);
        
        // Check if the response is an array before setting it in state
        if (Array.isArray(response.data)) {
          setCreatives(response.data);  // Set creatives to the array returned by the API
        } else {
          throw new Error("Invalid data format");
        }

        setLoading(false);
      } catch (err) {
        console.error("Error fetching creatives:", err);
        setError('Failed to fetch creatives');
        setLoading(false);
      }
    };

    fetchCreatives();
  }, []);  // Empty dependency array means this will run once when the component is mounted

  return (
    <div>
      {/* <div className="actions">
        <button className="create-btn">Create</button> 
        <button className="delete-btn">Delete</button>
      </div> */}

      {error && <div>{error}</div>}  {/* Display error if any */}

      {/* Creatives Table */}
      {!error && (
        <table className="table">
          <thead>
            <tr>
              {/* <th><input type="checkbox" /></th> */}
              <th>Creative Name</th>
              {/* <th>Status</th> */}
              {/* <th>On/Off</th> */}
              <th>Description</th>
              <th>Goal</th>
              <th>Impressions</th>
              {/* <th>Reach</th> */}
              <th>Clicked</th>
              <th>CTR (%)</th>
              {/* <th>Unique CTR (%)</th> */}
              <th>Conversions</th>
              <th>Conv. Rate (%)</th>
              <th>Dynamic Code</th>
            </tr>
          </thead>
          <tbody>
            {creatives.map((creative) => (
              <tr key={creative._id}>
                {/* <td><input type="checkbox" /></td> */}
                <td>{creative.name}</td>
                {/* <td>{creative.status}</td> */}
                {/* <td>
                  <label className="switch">
                    <input type="checkbox" checked={creative.isActive} readOnly />
                    <span className="slider round"></span>
                  </label>
                </td>*/}
                <td>{creative.description}</td>
                <td>{creative.goal}</td>
                <td>{creative.performance?.impressions}</td>  {/* Performance-related data */}
                {/* <td>{creative.performance?.reach}</td>*/}
                <td>{creative.performance?.clicks}</td>
                <td>{creative.performance ? ((creative.performance.clicks / creative.performance.impressions) * 100).toFixed(2) : 'N/A'}</td>
                {/* <td>{creative.performance ? ((creative.performance.clicks / creative.performance.reach) * 100).toFixed(2) : 'N/A'}</td> */}
                <td>{creative.performance?.conversions}</td>
                <td>{creative.performance ? ((creative.performance.conversions / creative.performance.impressions) * 100).toFixed(2) : 'N/A'}</td>
                <td><pre>
          {`<div class="creative-${creative._id}"></div><script src="https://track.howtoretain.in/dynamic-content.js?creativeId=${creative._id}&clientID=${clientID}"></script>`}
        </pre></td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Creatives;