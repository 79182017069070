import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Table.css'; // Common table styles
import CampaignCreationModal from './CampaignCreationModal'; // Import the new modal component

const Campaigns = ({ clientID }) => {
  const [campaigns, setCampaigns] = useState([]);  // Initialize as an empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);  // For handling modal visibility
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);  // Track selected campaigns

  // Fetch campaigns from the backend
  useEffect(() => {
    const fetchCampaigns = async () => {
      if (!clientID) return; // Do not fetch if clientID is not available
      console.log("Campaigns API called");

      try {
        const response = await axios.get('https://track.howtoretain.in/api/campaigns', {
          headers: {
            'Client-ID': clientID, // Include clientID in headers
          },
        });

        console.log(response);
        if (Array.isArray(response.data)) {
          setCampaigns(response.data);  // Set campaigns to the array returned by the API
        } else {
          throw new Error("Invalid data format");
        }

        setLoading(false);
      } catch (err) {
        console.error("Error fetching campaigns:", err);
        setError('Failed to fetch campaigns');
        setLoading(false);
      }
    };

    fetchCampaigns();
  }, [clientID]);  // Include clientID in the dependency array to refetch data if it changes

  const handleCreate = () => {
    setShowModal(true);  // Show the modal when Create is clicked
  };

  const handleCloseModal = () => {
    setShowModal(false);  // Close the modal
  };

  // Handle selecting or unselecting a campaign
  const handleSelectCampaign = (campaignId) => {
    if (selectedCampaigns.includes(campaignId)) {
      setSelectedCampaigns(selectedCampaigns.filter((id) => id !== campaignId));  // Unselect
    } else {
      setSelectedCampaigns([...selectedCampaigns, campaignId]);  // Select
    }
  };

  // Function to fetch and delete linked creatives, targeting groups, and finally the campaign
  const deleteLinkedData = async (campaignId) => {
    try {
      // Get specific campaign details to retrieve linked targetingGroups and creatives
      const campaignResponse = await axios.get(`http://localhost:5000/api/campaigns/${campaignId}`, {
        headers: {
          'Client-ID': clientID, // Include clientID in headers
        },
      });
      const campaignData = campaignResponse.data;

      // First: Delete creatives linked to the campaign
      if (campaignData.creatives.length > 0) {
        await Promise.all(
          campaignData.creatives.map(creativeId =>
            axios.delete(`http://localhost:5000/api/creatives/${creativeId._id || creativeId}`, {
              headers: {
                'Client-ID': clientID, // Include clientID in headers
              },
            })
          )
        );
        console.log('Deleted creatives linked to campaign:', campaignId);
      }

      // Second: Delete targeting groups linked to the campaign
      if (campaignData.targetingGroups.length > 0) {
        await Promise.all(
          campaignData.targetingGroups.map(groupId =>
            axios.delete(`http://localhost:5000/targeting-groups/${groupId._id || groupId}`, {
              headers: {
                'Client-ID': clientID, // Include clientID in headers
              },
            })
          )
        );
        console.log('Deleted targeting groups linked to campaign:', campaignId);
      }

      // Finally: Delete the campaign itself
      await axios.delete(`http://localhost:5000/api/campaigns/${campaignId}`, {
        headers: {
          'Client-ID': clientID, // Include clientID in headers
        },
      });
      console.log(`Campaign ${campaignId} deleted successfully`);

    } catch (error) {
      console.error("Error deleting linked data:", error);
      throw new Error("Failed to delete linked data");
    }
  };

  // Handle delete button click with confirmation
  const handleDelete = async () => {
    if (selectedCampaigns.length === 0) {
      alert("Please select at least one campaign to delete.");
      return;
    }

    // Show confirmation popup
    if (window.confirm(`Are you sure you want to delete these campaigns? This action cannot be undone, and all associated targeting groups and creatives will be deleted.`)) {
      try {
        // Loop through selected campaigns and delete them
        for (const campaignId of selectedCampaigns) {
          // Fetch and delete the linked creatives, targeting groups, and finally the campaign
          await deleteLinkedData(campaignId);
        }

        // Update state to remove the deleted campaigns from the UI
        setCampaigns(campaigns.filter(campaign => !selectedCampaigns.includes(campaign._id)));
        setSelectedCampaigns([]);  // Clear selected campaigns

      } catch (err) {
        console.error("Error deleting campaigns:", err);
        alert('Failed to delete campaigns.');
      }
    }
  };

  return (
    <div>
      <div className="actions">
        <button className="create-btn" onClick={handleCreate}>Create</button> {/* Create Button */}
        <button className="delete-btn" onClick={handleDelete}>Delete</button> {/* Delete Button */}
      </div>

      {error && <div>{error}</div>}  {/* Display error if any */}

      {/* Campaign Table */}
      {!error && (
        <table className="table">
          <thead>
            <tr>
              <th><input type="checkbox" disabled /></th>  {/* Header Checkbox (disabled for now) */}
              <th>Campaign Name</th>
              <th>Description</th>
              <th>Goal</th>
            </tr>
          </thead>
          <tbody>
            {campaigns.map((campaign) => (
              <tr key={campaign._id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedCampaigns.includes(campaign._id)}
                    onChange={() => handleSelectCampaign(campaign._id)}
                  />
                </td>
                <td>{campaign.name}</td>
                <td>{campaign.description}</td>
                <td>{campaign.goal}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Campaign Creation Modal */}
      {showModal && <CampaignCreationModal clientID={clientID} onClose={handleCloseModal} />}
    </div>
  );
};

export default Campaigns;