import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './IntegrationModal.css'; // Import custom styles

const IntegrationModal = ({ show, onHide, clientID }) => {
  const trackingURL = `<script src="https://track.howtoretain.in/public/js/tracking.js?clientID=${clientID}"></script>`;

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Integration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Tracking URL</h5>
        <Form.Group>
          <Form.Control
            as="textarea"
            rows={3}
            value={trackingURL}
            readOnly
            className="tracking-url-textarea"
            onClick={(e) => e.target.select()}
          />
        </Form.Group>
        <p>Copy the above tracking URL and paste it in the <code>&lt;head&gt;</code> section of your website.</p>
        <p><strong>Example:</strong></p>
        <pre className="code-snippet">
{`<!DOCTYPE html>
<html>
<head>
  <!-- Your existing meta tags, styles, etc. -->
  ${trackingURL}
</head>
<body>
  <!-- Your website content -->
</body>
</html>`}
        </pre>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
};

export default IntegrationModal;