import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import './App.css';
import { Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLifeRing, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import ProfileModal from './components/ProfileModal'; 
import IntegrationModal from './components/IntegrationModal'; 

const App = () => {
    const [clientID, setClientID] = useState(localStorage.getItem('clientID') || '');
    const [clientEmail, setClientEmail] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(!!clientID);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [showIntegrationModal, setShowIntegrationModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!clientID) {
            localStorage.removeItem('clientID');
            setIsLoggedIn(false);
        } else {
            localStorage.setItem('clientID', clientID);
            setIsLoggedIn(true);
            fetchClientDetails();
        }
    }, [clientID]);

    const fetchClientDetails = async () => {
        try {
            const response = await fetch(`https://track.howtoretain.in/api/client-profile`, {
              headers: {
                  'Client-ID': clientID,
              },
          }
        );
            const data = await response.json();
            setClientEmail(data.email);
        } catch (error) {
            console.error('Error fetching client details:', error);
        }
    };

    const handleProfileClick = () => setShowProfileModal(true);
    const handleIntegrationClick = () => setShowIntegrationModal(true);

    // Redirect to dashboard if logged in
    useEffect(() => {
        if (isLoggedIn) {
            navigate('/dashboard');
        }
    }, [isLoggedIn, navigate]);

    // Handle Support Button Click
    const handleSupportClick = () => {
        const encodedMessage = encodeURIComponent(`Hi, I need help. My Email: ${clientEmail}, Client ID: ${clientID}`);
        const supportURL = `https://wa.me/917990057721?text=${encodedMessage}`;
        window.open(supportURL, '_blank');
    };

    // Handle Logout
    const handleLogout = () => {
        setClientID('');
        localStorage.removeItem('clientID');
        navigate('/');
    };

    return (
        <div className="app-container">
            {/* Conditionally render the header with icons only if logged in */}
            {isLoggedIn && (
                <div className="header">
                    <div className="icons-container">
                        {/* Support Icon */}
                        <FontAwesomeIcon
                            icon={faLifeRing}
                            className="icon"
                            onClick={handleSupportClick}
                            title="Support"
                        />

                        {/* Profile Dropdown */}
                        <Dropdown align="end">
                            <Dropdown.Toggle variant="link" id="profile-dropdown">
                                <FontAwesomeIcon icon={faUser} className="icon" title="Profile" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={handleProfileClick}>Profile</Dropdown.Item>
                                <Dropdown.Item onClick={handleIntegrationClick}>Integration</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        {/* Logout Icon */}
                        <FontAwesomeIcon
                            icon={faSignOutAlt}
                            className="icon"
                            onClick={handleLogout}
                            title="Logout"
                        />
                    </div>
                </div>
            )}

            <Routes>
                {/* Login Route */}
                <Route
                    path="/"
                    element={
                        isLoggedIn ? <Navigate to="/dashboard" /> : <Login setClientID={setClientID} />
                    }
                />

                {/* Dashboard Route */}
                <Route
                    path="/dashboard"
                    element={
                        isLoggedIn ? (
                            <Dashboard clientID={clientID} />
                        ) : (
                            <Navigate to="/" />
                        )
                    }
                />
            </Routes>

            {/* Profile Modal */}
            <ProfileModal show={showProfileModal} onHide={() => setShowProfileModal(false)} clientID={clientID} />

            {/* Integration Modal */}
            <IntegrationModal show={showIntegrationModal} onHide={() => setShowIntegrationModal(false)} clientID={clientID} />
        </div>
    );
};

export default function AppWrapper() {
    return (
        <Router>
            <App />
        </Router>
    );
}